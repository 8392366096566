<template>
  <div class="verify">
    <v-app>
      <v-container fluid>
        <v-card class="pt-8 mt-6">
          <v-app-bar class="navbar" app height="50px">
            <div class="header-title">
                <h1>ยืนยันตัวตน</h1>
            </div>
          </v-app-bar>
          <v-form>
            <v-container fluid>
              <v-text-field
                label="รหัสยืนยันตัวตน"
                outlined
                dense
                color="#2E2253"
                hint="กรอกรหัสยืนยันตัวตนที่ได้รับจากsms"
                persistent-hint
                class="my-4 mx-2"
              ></v-text-field>
              <div class="text-center">
                <v-btn large color="blue" class="white--text my-2"
                  >ยืนยัน</v-btn
                >
              </div>
            </v-container>
          </v-form>
        </v-card>
      </v-container>
      <Footer />
    </v-app>
  </div>
</template>

<script>
import HeaderTitlePage from "@/components/HeaderTitlePage.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Verify",
  components: {
    HeaderTitlePage,
    Footer,
  },
};
</script>

<style scoped>
.navbar {
  position: absolute !important;
  width: 95%;
  top: -24px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: #2e2253 !important;
  border-radius: 3px !important;
}
.header-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-title h1 {
  color: #fff;
  font-weight: 400;
  font-size: 19px;
}
</style>